export const EAppPath = {
	MAIN: '/',

	SIGN_UP: '/signup',
	LOG_IN: '/login',

	SETTINGS: '/settings',

	EXPENSES: '/expenses',

	VENUES: '/venues',
	VENUES_CREATE: '/venues/new',
	VENUES_VIEW: '/venues/:id',
	VENUES_EDIT: '/venues/:id/edit',
} as const;
